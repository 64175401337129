import {createContext} from 'react';
import {Offer, RentForFreeOffersResponseDto} from "lib/api/backend.schemas";

interface KlmContextProps {
  availableOffers: Offer[];
  offers: RentForFreeOffersResponseDto;
  initialized: boolean;
  selectedOffer?: Offer;
}

export const KlmContext = createContext<KlmContextProps>({} as KlmContextProps);
