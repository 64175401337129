import clsx from 'clsx';
import {KlmContext} from 'context/KlmContext';
import {StarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {Controller, FormProvider} from 'hooks/custom-react-hook-form';
import {useGetTranslatedCategory} from 'hooks/useGetTranslatedCategory';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useRouter} from 'next/router';
import React, {useCallback, useContext, useEffect, useRef} from 'react';

import {parseDateTime} from '@internationalized/date';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

import {usePostApiV1CreateRentForFreeReservation} from 'lib/api/backend';
import {PostApiV1CreateRentForFreeReservation400} from 'lib/api/backend.schemas';

import {palette} from 'styles/mui/scss';

import {Box, DateRangePicker, Grid, Typography} from 'components/basic-components';
import scrollstyles from 'components/basic-components/ScrollBox/scrollBox.module.scss';

import {useBestPriceRechner} from '../../../../hooks/BPR/useBestPriceRechner';
import FormInputDistance from '../FormFields/FormInputDistance';
import FormInputVehicle from '../FormFields/FormInputVehicle';
import SubmitButton from '../FormFields/SubmitButton';
import StationSelectionFlyout from '../StationSelection/StationSelection';
import {TBestPriceRechnerProps} from '../types';
import styles from './BPRWidget.module.scss';
import { CustomIcon } from 'components/basic-components/CustomIcon/CustomIcon';
import FormInputOneWay from "components/section-components/bpr/FormFields/FormInputOneWay";
import FormInputGroup from "components/section-components/bpr/FormFields/FormInputGroup";
import { useHideOneWayForNotSupportedStationAndCategory } from '../hooks/useHideOneWayForNotSupportedStationAndCategory';

export interface BPRWidgetProps extends TBestPriceRechnerProps {
  isBPRActive?: boolean;
  setIsBPRActive?: any;
  isHeaderBPR?: boolean;
  variant?: 'modal' | 'base';
  minDate?: string;
  maxDate?: string;
  onSubmitHandler?: any;
  maxRentalDuration?: number;
  layout?: 'base' | 'klm';
  presetVip?: boolean;
  scrollBox?: boolean;
  hideOneWay?: boolean;
  isTeaser?: boolean;
}

const BPRWidget = ({
  bookableFrom,
  bookableTo,
  preferredVehicle: vehicle,
  presetStation,
  presetReturnStation,
  presetDistance,
  presetCategory,
  presetGroup,
  presetType,
  presetVip,
  withSessionConfig = false,
  origin = 'bestprice-normal',
  isBPRActive = false,
  setIsBPRActive,
  isHeaderBPR,
  variant = 'base',
  minDate,
  maxDate,
  onSubmitHandler,
  maxRentalDuration,
  layout,
  scrollBox,
  hideOneWay = false,
  isTeaser = false,
}: BPRWidgetProps) => {
  const {t} = useTranslation('bpr');
  const {
    push,
    query: {reservationId: pathReservationId},
    locale,
  } = useRouter();

  // Use the custom hook
  const {
    formMethods,
    onSubmit,
    emergencyOnPointerDownHandler,
    control,
    errors,
    fieldValues,
    stationsById,
    isDesktop,
    starcarDateRangePickerState,
    stations,
    getAvailableReturnStations,
    filteredOneWayStations,
    filteredOneWayReturnStations,
    isLoadingReturnStations,
    setOneWayStationsDistance,
    setOneWayStationsDistanceText,
    oneWayStationsDistanceText,
  } = useBestPriceRechner({
    bookableFrom,
    bookableTo,
    preferredVehicle: vehicle,
    presetStation,
    presetReturnStation,
    presetCategory,
    presetDistance,
    presetGroup,
    presetType,
    withSessionConfig,
    origin,
    isWidget: true,
    isTeaser,
    isHeaderBPR,
    minValue: minDate ? parseDateTime(minDate) : undefined,
    maxValue: maxDate ? parseDateTime(maxDate) : undefined,
    maxRentalDuration,
    layout
  });
  const {setCalendarOpen,setMinValue,initialMinValue, resetDatepicker, resetFormValues, setSelectedTimeField} =
    starcarDateRangePickerState;
  const wrapperRef = useRef(null);
  const getTranslatedCategory = useGetTranslatedCategory();

  const {
    mutate: postCreateRentForFree,
    data: createRentForFreeData,
    isSuccess: isSuccessCreateRentForFree,
    error: createRentForFreeErrors,
    isError: isErrorCreateRentForFree,
  } = usePostApiV1CreateRentForFreeReservation();

  const {clearErrors, setValue, setError, watch} = formMethods;
  const {selectedOffer} = useContext(KlmContext);
  const departureValue = watch('departure');
  const departureTimeValue = watch('departureTime');
  const arrivalValue = watch('arrival');
  const arrivalTimeValue = watch('arrivalTime');

  const onSubmitRentForFree = props => {
    const res = {
      departure: `${props.departure.year}-${props.departure.month}-${props.departure.day} ${props.departureTime}`,
      arrival: `${props.arrival.year}-${props.arrival.month}-${props.arrival.day} ${props.arrivalTime}`,
    };
    postCreateRentForFree({rentForFreeOffer: selectedOffer?.id, data: {...res}});
  };

  useEffect(() => {
    if (isSuccessCreateRentForFree && !isErrorCreateRentForFree) {
      push(`/reservation/${createRentForFreeData.id}/step3`);
    }
  }, [isSuccessCreateRentForFree]);

  useEffect(() => {
    // set backend errors
    const errors = (
      createRentForFreeErrors?.response?.data as PostApiV1CreateRentForFreeReservation400
    )?.errors;
    if (isErrorCreateRentForFree && errors) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key as any, {type: 'custom', message: value[0]});
      });
    }
  }, [isErrorCreateRentForFree]);

  useEffect(() => {
    if (isErrorCreateRentForFree) {
      clearErrors('departure');
      clearErrors('arrival');
    }
  }, [departureValue, arrivalValue, departureTimeValue, arrivalTimeValue]);

  const clickOutsideHandler = useCallback(() => {
    if (isBPRActive && (variant !== 'modal' || (isHeaderBPR && window.innerWidth <= 768))) {
      console.log('clickOutside',{isHeaderBPR, isBPRActive})
      setIsBPRActive(false);
      setCalendarOpen(false);
      setSelectedTimeField(null);
      setMinValue(initialMinValue)
    }
  }, [isBPRActive]);
  useOutsideAlerter(wrapperRef, clickOutsideHandler);
  const handleClick = () => {
    if (variant !== 'modal' || (isHeaderBPR && window.innerWidth <= 768)) {
      setIsBPRActive(true);
    }
  };
  const onFocusStation = useCallback(() => {
    if (wrapperRef?.current &&  isHeaderBPR && window.innerWidth <= 768) {
      // assuming mobile width <= 768px
      wrapperRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [wrapperRef.current, isHeaderBPR])
  useEffect(() => {
    if (!presetCategory) setValue('category', getTranslatedCategory('pkw'));
  }, [locale]);
  // [STARCAR-1921] Hide the Oneway Checkbox if station is Preset (but no return station is preset - KLM!) and reset the oneWay checkbox value to false, when switching category to make sure the layout doesnt break;
  let hideOneWayForCategoryIfStationDoesntSupportIt = useHideOneWayForNotSupportedStationAndCategory({formMethods, stations, presetReturnStation});

  return (
    <FormProvider {...formMethods}>
      <StarcarDateRangePickerStateContext.Provider value={starcarDateRangePickerState}>
        <Box
          ref={wrapperRef}
          className={clsx({[scrollstyles.scrollBar]: scrollBox})}
          onClick={handleClick}
          onContextMenu={handleClick}
          sx={{
            maxWidth: '1410px',
            backgroundColor: 'white',
            borderRadius: '4px',
            width: {xxs: '100%', md: '100%'},
            position: isBPRActive && variant === 'base' ? 'absolute' : 'relative',
            transition: 'width .2s ease-in-out',
            zIndex: 110,
            // maxHeight: '900px',
            overflow: scrollBox ? 'auto' : 'initial',
          }}
        >
          <form
            noValidate
            onSubmit={formMethods.handleSubmit(
              onSubmitHandler ? onSubmitHandler : layout === 'klm' ? onSubmitRentForFree : onSubmit,
            )}
          >
            <Controller
              name="category"
              control={control}
              render={({field: props}) => {
                return (
                  <>
                    <RadioGroup
                      aria-labelledby="choose-pkw-or-lkw"
                      className={styles.group}
                      {...props}
                    >
                      <Box
                        className={styles.wrapper}
                        sx={{
                          backgroundColor:
                            props.value === getTranslatedCategory('pkw')
                              ? palette.colorWhite
                              : palette.colorGrey03,
                          boxShadow:
                            props.value === getTranslatedCategory('pkw')
                              ? null
                              : 'inset 0 2px 5px 0 rgba(0, 0, 0, 0.1)',
                          borderTopLeftRadius: '4px',
                        }}
                      >
                        <FormControlLabel
                          disabled={!!presetCategory}
                          className={styles.radio}
                          value={t('bestpriceCategoryCar')}
                          control={<Radio id="" />}
                          label={<span className={styles.categoryWrapper}><CustomIcon name="car" className={styles.categoryIcon}></CustomIcon>{t('bestpriceCategoryCarLabel')}</span>}
                        />
                      </Box>
                      <Box
                        className={styles.wrapper}
                        sx={{
                          backgroundColor:
                            props.value === getTranslatedCategory('lkw')
                              ? palette.colorWhite
                              : palette.colorGrey03,
                          boxShadow:
                            props.value === getTranslatedCategory('lkw')
                              ? null
                              : 'inset 0 2px 5px 0 rgba(0, 0, 0, 0.1)',
                          borderTopRightRadius: '4px',
                        }}
                      >
                        <FormControlLabel
                          disabled={!!presetCategory}
                          className={styles.radio}
                          value={t('bestpriceCategoryTruck')}
                          control={<Radio />}
                          label={<span className={styles.categoryWrapper}><CustomIcon name="truck" className={styles.categoryIcon}></CustomIcon>{t('bestpriceCategoryTruckLabel')}</span>}
                        />
                      </Box>
                    </RadioGroup>
                  </>
                );
              }}
            />
            <Grid
              container
              columnSpacing={{xxs: '5px', xxl: '20px'}}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                padding: '20px 15px',
                rowGap: '20px',
                maxWidth: '100%',
                margin: 'auto',
              }}
            >
              <Grid
                xxs={12}
                sm={layout === 'klm' || (fieldValues.oneWay && !hideOneWay) ? 6 : 12}
                sx={{order: layout === 'klm' ? -4 : null}}
              >
                <StationSelectionFlyout
                  name="stationTextField"
                  control={control}
                  disabled={!!presetStation}
                  errors={errors}
                  onFocus={onFocusStation}
                  isBPRActive={isBPRActive}
                  setStationTextField={(value: string) => {
                    setValue('stationTextField', value, {shouldDirty: true, shouldTouch: true});
                    resetDatepicker();
                    resetFormValues();
                    setCalendarOpen(!fieldValues.oneWay);
                    clearErrors(['station', 'stationTextField']);
                  }}
                  setStation={station => {
                    setValue('station', station?.id || '', {
                      shouldDirty: true,
                      shouldTouch: true,
                    });

                    clearErrors(['station', 'stationTextField']);
                  }}
                  stations={fieldValues.oneWay ? filteredOneWayStations : stations}
                  selectedStation={stationsById?.[fieldValues.station] || null}
                  value={fieldValues.stationTextField}
                  isWidget={true}
                  showPopularStations={false}
                  isOneWay={fieldValues.oneWay}
                  getAvailableReturnStations={getAvailableReturnStations}
                  setOneWayStationsDistance={setOneWayStationsDistance}
                  setOneWayStationsDistanceText={setOneWayStationsDistanceText}
                />
              </Grid>
              {fieldValues.oneWay && !hideOneWay && !hideOneWayForCategoryIfStationDoesntSupportIt ? (
                <Grid xxs={12} sm={6} sx={{order: layout === 'klm' ? -3 : null}}>
                  <StationSelectionFlyout
                    name="returnStationTextField"
                    control={control}
                    errors={errors}
                    setStationTextField={(value: string) => {
                      setValue('returnStationTextField', value, {shouldDirty: true, shouldTouch: true});
                      setCalendarOpen(true);
                      clearErrors('returnStation');
                    }}
                    setStation={returnStation => {
                      setValue('returnStation', returnStation?.id || '', {shouldDirty: true, shouldTouch: true});
                      clearErrors('returnStation');
                    }}
                    stations={filteredOneWayReturnStations}
                    selectedStation={fieldValues.returnStation ? stationsById?.[fieldValues.returnStation] : null}
                    value={fieldValues.returnStationTextField}
                    isWidget={true}
                    showPopularStations={false}
                    isOneWay={fieldValues.oneWay}
                    isLoadingReturnStations={isLoadingReturnStations}
                    disabled={!fieldValues.station || !!presetReturnStation || !fieldValues.stationTextField}
                    getAvailableReturnStations={getAvailableReturnStations}
                    setOneWayStationsDistance={setOneWayStationsDistance}
                    setOneWayStationsDistanceText={setOneWayStationsDistanceText}
                  />
                </Grid>
              ): null}
              {layout !== 'klm' && !hideOneWay? (
                <Grid xxs={12} sx={{marginTop: '-15px'}}>
                  <Grid
                    container
                    justifyContent={'space-between'}
                    flexDirection={'row'}
                  >
                    <Grid xxs={hideOneWayForCategoryIfStationDoesntSupportIt ? 12 : 6}>
                      <FormInputOneWay
                        hideOneWayForCategoryIfStationDoesntSupportIt={hideOneWayForCategoryIfStationDoesntSupportIt}
                        control={control}
                        checkboxColor={palette.colorGrey60}
                        labelColor={palette.colorGrey60}
                        checkboxSize={'small'}
                      />
                    </Grid>
                    <Grid xxs={6} container justifyContent={'flex-end'}>
                      {oneWayStationsDistanceText && fieldValues.oneWay ? (
                        <Box className={styles.oneWayDistanceBox}>
                          <Typography variant="formLabeling" sx={{fontSize: {xxs: '10px', xl: '11px'}}}>
                            {t('oneWayDistance')}: {oneWayStationsDistanceText}
                          </Typography>
                        </Box>
                      ): null}
                    </Grid>
                  </Grid>
                </Grid>
              ): null}
              <Grid xxs={12}>
                <DateRangePicker widgetRef={wrapperRef} isWidget={true} />
              </Grid>
              <Grid
                xxs={12}
                sm={layout === 'klm' || (presetGroup && !vehicle) ? 6 : 12}
                sx={{order: layout === 'klm' ? -2 : null}}
              >
                <FormInputDistance
                  disabled={!!presetDistance || !(fieldValues?.stationTextField && fieldValues?.station || fieldValues?.distance) }
                  isWidget={true}
                  control={control}
                  errors={errors}
                />
              </Grid>
              {presetGroup ? (
                <Grid xxs={12} sm={6} sx={{order: layout === 'klm' ? -1 : null}}>
                  <FormInputGroup control={control} isWidget={true} errors={errors} />
                </Grid>
              ) : null}
              {vehicle ? (
                <Grid xxs={12} sm={vehicle ? 6 : 12}>
                  <FormInputVehicle control={control} isWidget={true} />
                </Grid>
              ) : null}
              {presetVip ? (
                <Grid xxs={12}>
                  <Typography>{t('vipGroupNextPossibleDateHint')}</Typography>
                </Grid>
              ) : null}

              <SubmitButton
                layout={layout}
                opacity={
                  (fieldValues.arrival &&
                    fieldValues.departure &&
                    fieldValues.station &&
                    !Object.keys(errors)?.length &&
                    1) ||
                  0.5
                }
                isWidget={true}
              />
            </Grid>
          </form>
        </Box>
      </StarcarDateRangePickerStateContext.Provider>
    </FormProvider>
  );
};

export default BPRWidget;

